@import "styles/_variables.scss";

.technologies {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 33%;
    height: 168px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
        border-bottom: 1px solid #D9D9D9;

    }

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(4),
    &:nth-child(5) {
        border-right: 1px solid #D9D9D9;
    }

    @media(max-width: $tabletPoint) {
        width: 50%;
        border-bottom: 1px solid #D9D9D9;
        border-right: 1px solid #D9D9D9;

        &:nth-child(6),
        &:nth-child(5) {
            border-bottom: none;
        }

        &:nth-child(2n) {
            border-right: none;
        }
    }

    @media(max-width: $mobilePoint) {
        width: 100%;
        border-right: none;
        &:nth-child(5) {
            border-bottom: 1px solid #D9D9D9;
        }
        &:nth-child(1),
        &:nth-child(2),
        &:nth-child(4),
        &:nth-child(5) {
            border-right: none;
        }
        &:last-child {
            border-bottom: none;
        }
    }

    &__title {
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 32px;
        color: $mainText;
        text-align: center;
        margin-bottom: 32px;
    }

    &__items {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 32px;
    }
}