@import "styles/_variables.scss";

.title{
    text-align: center;
    margin-bottom: 20px;
}

.subtitle{
    text-align: center;
    margin-bottom: 40px;
}

.container{
    margin-bottom: 32px;
}

.technologies{
    &__table{
        margin-top: 56px;
    }
}