@import "styles/_variables.scss";

.technologies {
    &__button {
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: $mainText;
        padding: 11px 16px;
        width: 100%;
        border-right: 1px solid #E3EAE6;
        transition: color .2s linear, background-color .2s linear;

        @media(max-width: $mobilePoint) {
            font-weight: 500;
            font-size: 14px;
            line-height: 22px;
            padding: 11px;
        }

        &:hover {
            cursor: pointer;
        }

        &:last-child {
            border-right: none;
        }

        &_active {
            transition: color .2s linear, background-color .2s linear;
            background-color: #E3EAE6;
            color: $greenBackground;
        }
    }
}