@import "styles/_variables.scss";

.technology {
    position: relative;
    &:hover{
        cursor: pointer;
        .technology__hover{
            display: block;
        }
    }
    &__image {
        position: relative;
    }

    &__hover {
        display: none;
        position: absolute;
        padding: 9px 16px;
        border-radius: 4px;
        font-family: $fontFamily;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 22px;
        color: #000000;
        background: $white;
        box-shadow: 0px 6px 40px rgba(0, 0, 0, 0.08);
        z-index: 10;
        white-space: nowrap;
        left: 50%;
        top: 50%;
        transform: translate(-50%, 70%);
    }
}