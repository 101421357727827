@import "styles/_variables.scss";

.technologies{
    &__buttons{
        display: flex;
        border: 1px solid #E3EAE6;
        border-radius: 4px;
        max-width: 480px;
        margin: 0 auto;
    }
}